import instance from "./init";

export const getLogs = async () => {
  try {
    const res = await instance.get("/logs");
    return res.data;
  } catch (error) {
    console.error(error);
  }
};
