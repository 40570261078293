import { useEffect, useState } from "react";
import "./App.css";

const logAPI = require("./api/log");

function App() {
  const [data, setData] = useState([]);

  useEffect(() => {
    logAPI.getLogs().then((res) => {
      // Sort the data based on the date before setting it to the state
      const sortedData = res.sort(
        (a, b) => new Date(b.date) - new Date(a.date)
      );
      setData(sortedData);
    });
  }, []);

  return (
    <div className="App">
      <h1>Server Room Logs</h1>
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Data</th>
            <th>Date</th>
            <th>Time</th>
          </tr>
        </thead>
        <tbody>
          {data.map((val, key) => {
            const dateTime = new Date(val.date);
            // -5:30 from the time
            dateTime.setHours(dateTime.getHours() - 5);
            dateTime.setMinutes(dateTime.getMinutes() - 30);
            const formattedDate = dateTime.toLocaleDateString();
            const formattedTime = dateTime.toLocaleTimeString();
            return (
              <tr key={key}>
                <td>{val.name}</td>
                <td>{val.data}</td>
                <td>{formattedDate}</td>
                <td>{formattedTime}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default App;
